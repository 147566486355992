.arrow-container {
    cursor: pointer;
    /* Add other styles for the arrow container here */
  }
  
  .arrow-label {
    /* Add styles for the arrow label here */
  }
  
  .arrow-icon {
    /* Add styles for the arrow icon here */
  }

  /* arrow.css */
.hero-arrow {
    animation: moveArrow 2s ease infinite;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  
  @keyframes moveArrow {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .arrow-down {
    font-size: 24px;
    animation: bounce 2s infinite;
    color: #FFFFE8;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  @media only screen and (max-width: 767px) {
    .arrow-down{
      margin-top: 100px;
    }
  }