.community-container {
  font-family: 'Arial', sans-serif;
  color: white;
  text-decoration: none;
}

.community-background {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.community-content {
  min-width: 600px;
  height: auto;
  padding: 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75) !important;
  border-radius: 50px !important;
  background-color: #1E3A8A !important;
  border-width: 10px;
  border-color: #F9C80E;
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
}

.community-content h1 {
  font-size: 36px;
  margin-bottom: 10px;
  text-align: center;
}

.community-content p {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.community-content button {
  font-size: 18px;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.community-content button:hover {
  background-color: #e65100;
}

.submit-button {
  font-size: 22px;
  padding: 15px 30px;
}

.leaderboard,
.videos,
.inspiration {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 32px;
}

.leaderboard h2,
.videos h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.countdown {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

.submitter {
  font-size: 16px;
  margin-bottom: 20px;
}

.challenge-link {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 600px) {

  .community-content {
    width: 90vw;
    min-width: 10px;
  }
  
}