.homepage-container {
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.video-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  padding-right: 20px; /* Add padding to accommodate the scrollbar */
  overflow-x: auto;
}

/* Adjust the grid for mobile devices */
@media (max-width: 600px) {
  .video-grid-container {
    grid-template-columns: 1fr;
  }
}
