.challenge-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  min-width: 300px;
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.challenge-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.challenge-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.challenge-card-info {
  padding: 16px;
}

.challenge-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.challenge-card-description {
  font-size: 14px;
  color: #777;
}

.community-challenges-header {
  text-align: center;
  margin-bottom: 2rem;
}

.challenge-container {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding: 1rem;
}

.challenge-card {
  flex: 0 0 auto;
  width: 300px;
  margin-bottom: 1rem;
}

