.challengeCard {
  padding: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.challengeCardPaper {
  height: auto;
  padding: 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75) !important;
  border-radius: 50px !important;
  background-color: #1E3A8A !important;
  border-width: 10px;
  border-color: #F9C80E;
  border-style: solid;
}

.challengeCardBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.challengeCardTitle {
  font-size: 24px;
  font-weight: 900;
  color: #F9C80E;
}

.challengeCardDescription {
  font-size: 16px;
  color: #F9C80E;
  margin-bottom: 20px;
}

.challengeCardLink {
  text-decoration: none;
}

.challengeCardRequirements {
  font-size: 16px;
  color: #F9C80E;
  margin-bottom: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.challengeCardButton {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.challengeCardContent {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.challengeCardButton:hover {
  background-color: #0056b3;
}

.challengeChips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


@media only screen and (max-width: 767px) {

  .challengeCardPaper {
    width: 90vw;
    margin-top: 20px;
  }



}