.featured-musician-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .profile-picture {
    width: 500px;
    height: 500px ;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
  }
  
  .musician-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #F9C80E;
  }
  
  .musician-info {
    font-size: 1.2rem;
    color: #fff;
  }
  
  .social-links {
    display: flex;
    gap: 20px;
  }
  
  .social-link {
    font-size: 1.5rem;
    color: #333;
    text-decoration: none;
  }
  
  .social-link:hover {
    color: #666;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    20% {
      opacity: 1;
      transform: translateY(0);
    }
    80% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-50px);
    }
  }
  