.issue-challenge-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 25vw;
    background-color: #0056b3;
    border: 10px solid #EAB308;
    border-radius: 30px !important;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
}

.swal {
    z-index: 10000 !important;
}

.textField {
    background-color: white;
    width: 300px;
}

@media (max-width: 768px) {

    .issue-challenge-form {
        width: 90%;
    }

    .textField {
        width: 70vw !important;
    }

}