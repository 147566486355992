.video-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 90%;
}

.video-thumbnail-card {
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 350px;
  margin: 20px;
}

.thumbnail-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (9 / 16 * 100 = 56.25%) */
  overflow: hidden;
  min-height: 197px; /* Minimum height based on 16:9 aspect ratio (350 * 9 / 16 = 197) */
}

.thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-thumbnail-info {
  padding: 15px;
  color: #ccc;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.video-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.video-user,
.video-style,
.video-instruments,
.video-challenge {
  font-size: 14px;
  margin-bottom: 5px;
}

.video-thumbnail-text {
  color: #ccc;
  font-weight: bolder;
  padding: 20px;
  text-align: left;
}

.modalStyles {
  display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
    width: '640px';
    height: '400px';
    margin: 'auto';
    padding: '20px';
    border-radius: '4px';
    box-shadow: '0 0 10px rgba(0, 0, 0, 0.5)';
}

@media only screen and (max-width: 480px) {
  .video-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .video-thumbnail-card {
    width: 300px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .video-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  .video-thumbnail-card {
    width: 350px;
  }
}
