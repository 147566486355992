.melodymash-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background10.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}


.melodymash-background {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.melodymash-content {
  background-color: rgba(0, 0, 0, .7);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 70vw;
  margin-top: 100px;
}

.challenge-name {
  font-size: 2rem;
  color: #F9C80E;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.challenge-details-container {
  margin: 40px;
}

.challenge-details-container .challenge-details {
  text-align: left;
}


.challenge-details {
  font-size: 1.5rem;
  color: #fff;
  font-family: Roboto;
  font-weight: normal;
  justify-content: center;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
}

.challengeImageHeader {
  width: 20%;
}

.submit-button {
  background-color: #ff7f50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

.button {
  background-color: #f44336;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #c0392b;
}

.submit-button {
  background-color: #F9C80E;
  color: #333;
  font-weight: bolder;
  padding: 14px 28px;
}

.submit-button:hover {
  background-color: #e0b104;
}


.leaderboard {
  margin: 2rem 0;
}

.videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-gap: 2rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  max-width: 90%;
  margin: 2rem auto;
}

.videos-title {
  font-size: 3rem;
  color: #F9C80E;
  font-weight: 900;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.leaderboard-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-gap: 2rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  max-width: 90%;
  margin: 2rem auto;
}

.leaderboard-heading {
  margin-top: 50px;
  color: #fff;
}

.video-card {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}

.video-thumb {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.video-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: 'roboto';
  font-weight: 800;
  flex-direction: column;
}

.video-embed-container {
  /* This will make the video maintain a 16:9 aspect ratio */
  position: relative;
  width: 100%;
  margin: 20px;
  /* 100% / (16 / 9) */
}

.video-embed {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vote-button {
  background-color: #ff9900;
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.rank {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff9900;
  color: #ffffff;
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-gap: 2rem;
  padding: 2rem;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 0.5rem;
  max-width: 90%;
  margin: 2rem auto;
}

@media screen and (max-width: 600px) {

  .video-card {
    max-width: 75vw;
  }

}