.challengeCard {
  padding: 1rem;
  padding: 1rem;
}

.challengeCardPaper {
  width: 20vw;
  height: 60vh;
  padding: 20px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75) !important;
  border-radius: 50px !important;
}

.challengeCardBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.challengeCardTitle {
  font-size: 24px;
  font-weight: 900;
  color: #333;
}

.challengeCardDescription {
  font-size: 1.5rem;
  color: #666;
  margin: 20px;
  font-family: 'roboto';
}

.challengeCardLink {
  text-decoration: none;
}

.challengeCardButton {
  padding: 10px 20px;
  background-color: #EAB308 !important;
  color: #1E3A8A !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px;
}

.challengeCardContent {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.challengeCardButton:hover {
  background-color: #0056b3;
}

.challengeChips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}


@media only screen and (max-width: 767px) {

  .challengeCardPaper {
    width: 80vw;
    height: 70vh;
    margin-top: 20px;
  }



}