@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
}

.header-row {
    padding: 15px 100px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
}

.brand-logo {
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

.header-text {
    font-size: 1.3rem;
    font-family: 'Roboto';
    margin-left: 10px;
    color: #EAB308;
    text-decoration: none;
}

.hamburger path {
    fill: none;
    stroke: #EAB308;
    stroke-linecap: round;
}

.menu-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
}

.fullpage-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: none;
    z-index: 10;
}

.fullpage-menu-inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 100px 60px;
}

.menu-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.menu-bg span {
    height: 33.334%;
    width: 100%;
    background-color: #8447FF;
    display: block;
}

nav {
    position: relative;
    z-index: 10;
}

nav ul {
    list-style-type: none;
}

nav li {
    overflow: hidden;
    transition: transform 300ms ease-in-out 0s;
}

nav li+li {
    margin-top: 20px;
}

.nav-List {
    font-size: 8rem;
    font-family: 'Architects Daughter';
    color: #FFFFE8;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
    line-height: 1;
}

nav li:hover {
    transform: translateX(50px);
}

.header-nav-footer {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px 60px;
}

.social-links {
    list-style-type: none;
    display: flex;
    align-items: center;
    width: 100%;
}

.social-links li {
    font-size: 2rem;
    font-weight: 500;
    font-family: 'Architects Daughter';
}

.social-links li+li {
    margin-left: 16px;
}

.social-links li:last-child {
    margin-left: auto;
    color: #FFFFE8;
}

.social-links a {
    color: #FFFFE8;
    font-family: 'Architects Daughter';
    text-decoration: none;
}

.linkStyle {
    text-decoration: none;
}




@media only screen and (max-width: 767px) {

    .header-row {
        padding: 15px 30px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        background-color: transparent;
    }

    .nav-List {
        font-size: 2rem;
        font-family: 'Architects Daughter';
        color: #415063;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 400;
        display: inline-block;
        line-height: 1;
    }

    .social-links li {
        font-size: 1.2rem;
        font-weight: 500;
        font-family: 'Architects Daughter';
    }

    .header-nav-footer {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 40px 14px;
    }


}